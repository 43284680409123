import React from "react";
import JmdNavbar from "./JmdNavbar";

const ServiceHeader = () => {
  return (
    <section className="products-header-bg-vh products-header-bg header-sec">
      <JmdNavbar
        bg_transparent="bg-transparent"
        bg_linear="bg-linear"
        prodlogonone="d-none"
        prodlogoblock="d-block"
        navlinkclr="text-white"
        serviceopacity08="opacity-08"
        serviceopacity100="opacity-100"
        service="service"
        cntctbtn="product-page-button"
        menuicon="white-bar"
      />
    </section>
  );
};

export default ServiceHeader;
