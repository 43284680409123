import React from "react";
import AboutUsPageHeader from "../components/AboutUsPageHeader";
import GalleryHeader from "../components/GalleryHeader";
import GalleryCabin from "../components/GalleryCabin";
import PremiumCabin from "../components/PremiumCabin";
import CapusleEle from "../components/CapusleEle";
import ElevatorsDoor from "../components/ElevatorsDoor";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";

const Gallery = () => {
  return (
    <>
      <GalleryHeader />
      <GalleryCabin />
      <PremiumCabin />
      <CapusleEle />
      <ElevatorsDoor />
      <GetInTouch aboutusbg="bg-transparent" wave="d-none" />
      <JmdFooter />
    </>
  );
};

export default Gallery;
