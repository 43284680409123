import React, { useEffect, useState } from "react";
import logo from "../assets/svg/logo.svg";
import whitelogo from "../assets/svg/whitelogo.svg";
import { Link } from "react-router-dom";

const JmdNavbar = (props) => {
  const [show, setShow] = useState(false);
  if (show) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "visible";
  }
  console.log(show);
  return (
    <section className={`py-3 nav ${props.bg_transparent} ${props.navbg}`}>
      <div className="container mx-auto custom_container">
        <div className="d-flex align-items-center justify-content-between">
          <a className={`${props.prodlogonone} ${props.logoblock}`} href="#">
            <img className="logo" src={logo} alt="logo" />
          </a>
          <a className={`${props.logonone} ${props.prodlogoblock}`} href="#">
            <img className="logo" src={whitelogo} alt="logo" />
          </a>
          <div className="d-flex gap-4 align-items-center">
            <ul
              className={
                show === false
                  ? `d-flex align-items-center nav-hidden m-0 p-0 ${props.bg_linear}`
                  : `nav-show d-lg-none d-flex align-items-center  ${props.bg_linear}`
              }
            >
              <li>
                <Link
                  to="/"
                  onClick={() => setShow(false)}
                  className={`ff-arial fs_xsm position-relative text-black ${props.navlinkclr} ${props.opacity08} ${props.home} ${props.aboutopacity08} ${props.galleryopacity08} ${props.serviceopacity08}`}
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  to="/aboutus"
                  onClick={() => setShow(false)}
                  className={`ff-arial fs_xsm opacity-08 text-black position-relative ${props.navlinkclr} ${props.abooutopacity100} ${props.about}`}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/service"
                  onClick={() => setShow(false)}
                  className={`ff-arial fs_xsm opacity-08 text-black position-relative ${props.navlinkclr} ${props.serviceopacity100} ${props.service}`}
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/product"
                  onClick={() => setShow(false)}
                  className={`ff-arial fs_xsm opacity-08 text-black position-relative ${props.navlinkclr} ${props.opacity100} ${props.product} ${props.aboutopacity08}`}
                >
                  Product
                </Link>
              </li>
              <li>
                <Link
                  to="/gallery"
                  onClick={() => setShow(false)}
                  className={`ff-arial fs_xsm opacity-08 text-black position-relative ${props.navlinkclr} ${props.galleryopacity100} ${props.gallery}`}
                >
                  Gallery
                </Link>
              </li>
              <a
                onClick={() => setShow(false)}
                href="#contactUs"
                className="d-lg-none"
              >
                <button
                  className={`common-button ls-046 ff-petrona fs_xsm ${props.cntctbtn}`}
                >
                  Contact Us
                </button>
              </a>
            </ul>
            <div className="d-lg-none">
              <button
                className={
                  show === false ? "hamburger relative" : "hamburger-2"
                }
                onClick={() => setShow(!show)}
              >
                <span className={`bar ${props.menuicon}`}></span>
                <span className={`bar ${props.menuicon}`}></span>
                <span className={`bar ${props.menuicon}`}></span>
              </button>
            </div>
            <a href="#contactUs" className="d-lg-block d-none">
              <button
                className={`common-button ls-046 ff-petrona fs_xsm ${props.cntctbtn}`}
              >
                Contact Us
              </button>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JmdNavbar;
