import React from "react";
import JmdNavbar from "./JmdNavbar";

const GalleryHeader = () => {
  return (
    <section className="products-header-bg-vh products-header-bg header-sec">
      <JmdNavbar
        bg_transparent="bg-transparent"
        bg_linear="bg-linear"
        prodlogonone="d-none"
        prodlogoblock="d-block"
        navlinkclr="text-white"
        galleryopacity08="opacity-08"
        galleryopacity100="opacity-100"
        gallery="gallery"
        cntctbtn="product-page-button"
        menuicon="white-bar"
      />
    </section>
  );
};

export default GalleryHeader;
