import React from "react";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const CommercialEleAdv = () => {
  return (
    <>
      <img loading="lazy" className="w-100 top-wave" src={topwave} alt="wave" />
      <section className="py-5 bg-grey product-advantage-sec">
        <div className="custom_container container">
          <h2 className="product-page-common-heading text-center">
            Advantages
          </h2>
          <div className="mt-5">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Energy Effective
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Eco-smart elevators redefine sustainability, minimizing energy use
              without performance compromise. Regenerative drives, smart
              controls, and innovative design offer cost-effective solutions
              that align with energy-saving initiatives. Ideal for eco-conscious
              establishments, they optimize efficiency & reduce carbon footprint
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Digital Intelligence
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Elevators seamlessly combine elegance and customizable designs,
              enhancing aesthetics and functionality. From modern to classic,
              our elevators harmonize with your space, making a lasting
              impression
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Corporate Aesthetics
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Elevate user experience and optimize operations by leveraging
              easily accessible insights. Utilize real-time information to make
              informed decisions and allocate resources efficiently, enhancing
              your establishment's functionality and efficiency
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Space Optimization
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Commercial elevators maximize the efficient use of available
              space. Their compact designs ensure minimal footprint while
              accommodating significant passenger traffic. This space-saving
              feature is especially valuable in urban settings or locations with
              limited square footage
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Customizable Design
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Tailor your elevator's aesthetics to match your establishment's
              ambiance with JMD Elevators. Customize cabin finishes, lighting,
              and more, creating a cohesive and inviting atmosphere that aligns
              seamlessly with your interior design
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Reliability and Low Maintenance
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Commercial elevators are built for long-lasting reliability,
              minimizing downtime and maintenance costs. Advanced engineering
              and quality components ensure smooth operation and longevity,
              reducing disruptions to daily operations and providing a
              cost-effective solution in the long run
            </p>
          </div>
        </div>
      </section>
      <img loading="lazy" className="w-100 bottom-wave" src={bottomwave} alt="wave" />
    </>
  );
};

export default CommercialEleAdv;
