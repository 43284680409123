import React from "react";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const ChaieEleAdv = () => {
  return (
    <>
      <img loading="lazy" className="w-100 top-wave" src={topwave} alt="wave" />
      <section className="py-5 bg-grey product-advantage-sec">
        <div className="custom_container container">
          <h2 className="product-page-common-heading text-center">
            Advantages
          </h2>
          <div className="mt-5">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Mobility Freedom
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Chair elevators empower individuals with limited mobility,
              offering a safe and comfortable way to navigate stairs. This
              independence-enhancing solution enables easy access to different
              levels of your home.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Space Conservation
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Chair elevators require minimal space, making them ideal for homes
              with restricted stairwell dimensions. Their compact design
              preserves your home's layout while providing a practical solution
              for vertical movement.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Quick Installation
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Chair elevators are relatively easy to install, requiring minimal
              structural modifications. This efficient setup ensures prompt
              accessibility without significant disruption to your home.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              User-Friendly Controls
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              With intuitive controls, chair elevators are straightforward to
              operate. Their ergonomic design caters to users of varying
              abilities, ensuring a smooth and stress-free transportation
              experience.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Safety Assurance
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Chair elevators prioritize safety with features like seatbelts,
              obstruction sensors, and emergency stops. These mechanisms ensure
              secure transportation, providing peace of mind for both users and
              caregivers.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Affordable Accessibility
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Chair elevators offer a cost-effective solution for maintaining
              independence at home. Their accessibility benefits help avoid
              expensive home modifications while facilitating convenient
              movement for those who need it most.
            </p>
          </div>
        </div>
      </section>
      <img loading="lazy" className="w-100 bottom-wave" src={bottomwave} alt="wave" />
    </>
  );
};

export default ChaieEleAdv;
