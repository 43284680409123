import React from "react";
import { Col, Row } from "react-bootstrap";
import cabindesign from "../assets/gif/cabinDesign.mp4";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const OurServices = () => {
  return (
    <>
      <img loading="lazy" className="w-100 top-wave" src={topwave} alt="wave" />
      <section className="py-5 bg-grey sec-our-service">
        <div className="custom-container container">
          <Row className="align-items-center flex-column-reverse flex-lg-row">
            <Col xl={7} lg={6}>
              <div className="pe-xl-4 me-xl-5 me-lg-4 mt-4 text-center text-lg-start">
                <h2 className="common-heading-class mb-2">Our Services</h2>
                <div className="pe-xxl-3">
                  <p className="ff-arial lh-150 fs_xsm opacity-07 pt-1 pe-xl-5 me-xl-5">
                    Complete Elevator Services: From new installations to annual
                    maintenance contracts, modifications, and comprehensive
                    maintenance contracts, we cover residential, commercial, and
                    industrial properties. Our skilled engineers and technicians
                    ensure precision and safety
                  </p>
                </div>
                <div className="pt-sm-4 pt-2">
                  <a href="#contactUs">
                    <button className="common-button ff-petrona fs_md mt-lg-2">
                      Contact Us
                    </button>
                  </a>
                </div>
              </div>
            </Col>
            <Col xl={5} lg={6}>
              <div className="text-center">
                <video className="cabin-design-gif" autoPlay muted loop>
                  <source src={cabindesign} type="video/mp4" />
                </video>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <img loading="lazy" className="w-100 bottom-wave" src={bottomwave} alt="wave" />
    </>
  );
};

export default OurServices;
