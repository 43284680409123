import React from "react";
import Slider from "react-slick";
import {
  expertisecard,
  expertisecardnextarrow,
  expertisecardprevarrow,
} from "../pages/MapData";

const ElevatorExpertise = () => {
  const button = React.useRef();
  var settings = {
    autoplay: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <section className="py-5 elevator-expertise-sec">
      <div className="custom_container container pt-lg-5">
        <h2 className="common-heading-class text-center">
          Discover Our Elevator Expertise
        </h2>
        <Slider {...settings} ref={button}>
          {expertisecard.map((val) => {
            return (
              <div className="mt-lg-5 pt-lg-5 mt-4 px-3">
                <div className="expertise-cards text-center px-4 py-4 position-relative h-100">
                  <div className="bg-blue-circle d-flex align-items-center justify-content-center mx-auto">
                    {val.cardicons}
                  </div>
                  <h3 className="mb-0  ff-merri fs_xxl lh-100 mt-4 pt-2">
                    {val.heading}
                  </h3>
                  <p className="mt-3 ff-arial lh-150 fs_xsm opacity-07">
                    {val.para}
                  </p>
                </div>
              </div>
            );
          })}
        </Slider>
        <div className="d-flex justify-content-center gap-3 mt-5">
          <button
            className="prev-next-btn d-flex align-items-center justify-content-center transition-300"
            onClick={() => button.current.slickPrev()}
          >
            {expertisecardnextarrow}
          </button>
          <button
            className="prev-next-btn d-flex align-items-center justify-content-center transition-300"
            onClick={() => button.current.slickNext()}
          >
            {expertisecardprevarrow}
          </button>
        </div>
      </div>
    </section>
  );
};

export default ElevatorExpertise;
