import React from "react";
import hospitalElevator from "../assets/png/prodhospitalele.png";
import { Col, Row } from "react-bootstrap";

const HospitalEle = () => {
  return (
    <section className="py-5">
      <div className="custom_container container pb-lg-5">
        <h1 className="ff-baskerville fs_3xl lh-100 product-heading-text-stroke text-center">
          Hospital Elevators
        </h1>
        <Row className="mt-5 pt-lg-5 pt-3 align-items-center flex-column-reverse flex-lg-row">
          <Col lg={6}>
            <div className="text-center text-lg-start mt-4">
              <h2 className="product-page-common-heading">Healthcare Rise</h2>
              <p className="ff-arial clr-light-black-2 lh-150 fs_xsm pe-lg-5 mb-0 mt-3">
                Discover JMD Elevators' Hospital Elevators, designed to improve
                patient mobility in healthcare spaces. With a focus on safety
                and speed, our elevators guarantee efficient transportation for
                patients and medical staff. Easy-to-clean and built for quick
                access, they ensure a seamless experience. Trust us to deliver
                reliable vertical mobility solutions tailored to healthcare
                needs.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="image-div-border-radius overflow-hidden">
              <img loading="lazy"
                className="w-100 image-border-radius transition-300 img-hover"
                src={hospitalElevator}
                alt="elevator"
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default HospitalEle;
