// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCmfU_pVWiuNRozGhmZjLF1828V_y7jMcA",
  authDomain: "jmdele-b6b77.firebaseapp.com",
  projectId: "jmdele-b6b77",
  storageBucket: "jmdele-b6b77.appspot.com",
  messagingSenderId: "959882103671",
  appId: "1:959882103671:web:0d59ee4f483ea7c00aaf72",
  measurementId: "G-J4EL74Q09Z",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app, analytics };
