import React from "react";
import ProductHeader from "../components/ProductHeader";
import OurOffering from "../components/OurOffering";
import ElevatorExpertise from "../components/ElevatorExpertise";
import OurCollection from "../components/OurCollection";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";

const Products = () => {
  return (
    <>
      <ProductHeader />
      <OurOffering />
      <ElevatorExpertise />
      <OurCollection />
      <GetInTouch />
      <JmdFooter />
    </>
  );
};

export default Products;
