import React from "react";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const GoodsPassEleAdv = () => {
  return (
    <>
      <img loading="lazy" className="w-100 top-wave" src={topwave} alt="wave" />
      <section className="py-5 bg-grey product-advantage-sec">
        <div className="custom_container container">
          <h2 className="product-page-common-heading text-center">
            Advantages
          </h2>
          <div className="mt-5">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Space Efficiency
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Our dual-purpose elevator maximizes available space, a perfect
              solution for compact locations where separate elevators might be
              impractical, ensuring optimal use of valuable floor space.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">Cost Savings</h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Investing in a single multi-use elevator significantly reduces
              construction and maintenance costs compared to installing separate
              units, offering a cost-effective solution for businesses and
              buildings.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">Convenience</h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Users enjoy streamlined vertical mobility with reduced wait times,
              enhancing operational efficiency and convenience for both
              passengers and goods.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">Safety</h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Equipped with state-of-the-art safety features, our elevator
              ensures the secure transportation of passengers and goods,
              prioritizing safety in all operations.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">Versatility</h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Suited for a wide range of industries, including hospitality,
              healthcare, and commercial sectors, our elevator adapts
              effortlessly to diverse needs.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">Productivity</h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              By enabling the simultaneous movement of people and goods, our
              elevator enhances workflow efficiency, contributing to increased
              productivity within businesses and buildings.
            </p>
          </div>
        </div>
      </section>
      <img loading="lazy" className="w-100 bottom-wave" src={bottomwave} alt="wave" />
    </>
  );
};

export default GoodsPassEleAdv;
