import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingPage from "./pages/LandingPage";
import CommercialElevator from "./pages/CommercialElevator";
import { Route, Routes, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import IndustrialElevator from "./pages/IndustrialElevator";
import HospitalElevators from "./pages/HospitalElevators";
import HomeElevator from "./pages/HomeElevator";
import ChairElevator from "./pages/ChairElevator";
import GlassElevator from "./pages/GlassElevator";
import LopCop from "./pages/LopCop";
import GoodsElevators from "./pages/GoodsElevators";
import CarElevator from "./pages/CarElevator";
import AboutUs from "./pages/AboutUs";
import Products from "./pages/Products";
import Gallery from "./pages/Gallery";
import Services from "./pages/Services";
import GoodscumPassenger from "./pages/GoodscumPassenger";
import DumbWaiterElevator from "./pages/DumbWaiterElevator";
import PreLoader from "./components/PreLoader";
import { analytics } from "./pages/AnalyticsFile";
function App() {
  useEffect(() => {
    try {
      analytics.logEvent('page_view');
    } catch (error) {
      console.error("Error logging event:", error);
    }
  }, []);
  const routePath = useLocation();
  const [loading, setLoading] = useState(false);
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 4000);
  }, []);
  return (
    <>
      {loading ? (
        <PreLoader />
      ) : (
        <>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/aboutus" element={<AboutUs />} />
            <Route path="/service" element={<Services />} />
            <Route path="/product" element={<Products />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route
              path="/commercialelevator"
              element={<CommercialElevator />}
            />
            <Route path="/hospitalelevator" element={<HospitalElevators />} />
            <Route
              path="/industrialelevator"
              element={<IndustrialElevator />}
            />
            <Route path="/homeelevator" element={<HomeElevator />} />
            <Route path="/chairelevator" element={<ChairElevator />} />
            <Route path="/glasselevator" element={<GlassElevator />} />
            <Route path="/lop-cop" element={<LopCop />} />
            <Route path="/goodselevator" element={<GoodsElevators />} />
            <Route path="/carselevator" element={<CarElevator />} />
            <Route
              path="/goodscumpassengerelevator"
              element={<GoodscumPassenger />}
            />
            <Route
              path="/dumbwaiterelevator"
              element={<DumbWaiterElevator />}
            />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
