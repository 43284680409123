import React from "react";
import ServiceHeader from "../components/ServiceHeader";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";
import PageServiceSec from "../components/PageServiceSec";
import AllNorthIndia from "../components/AllNorthIndia";
import ExperiencedStaf from "../components/ExperiencedStaf";
import DeliveryInTime from "../components/DeliveryInTime";
import IndustryExperience from "../components/IndustryExperience";
import OurCollection from "../components/OurCollection";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";

const Services = () => {
  return (
    <>
      <ServiceHeader />
      <PageServiceSec />
      <div className="my-5">
        <img className="w-100 top-wave" src={topwave} alt="wave" />
        <div className="bg-grey product-advantage-sec py-5">
          <h1 className="common-heading-class text-center">Why Choose Us?</h1>
          <AllNorthIndia />
          <ExperiencedStaf />
          <DeliveryInTime />
          <IndustryExperience />
        </div>
        <img className="w-100 bottom-wave" src={bottomwave} alt="wave" />
      </div>
      <OurCollection />
      <GetInTouch />
      <JmdFooter />
    </>
  );
};

export default Services;
