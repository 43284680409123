import React from "react";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";
import { Col, Row } from "react-bootstrap";
import mdimage from "../assets/png/directorimage.png";
import { tick } from "../pages/MapData";

const CompanyGoal = () => {
  return (
    <>
      <img loading="lazy" className="w-100 top-wave" src={topwave} alt="wave" />
      <section className="py-5 bg-grey product-advantage-sec">
        <div className="custom_container container">
          <Row className="align-items-center flex-column-reverse flex-lg-row">
            <Col
              className="text-center text-lg-start mt-4 mt-lg-0"
              xl={7}
              lg={6}
            >
              <h2 className="common-heading-class mb-2">Our Company Goal</h2>
              <p className="ff-arial lh-150 fs_xsm opacity-07 pt-1 pe-lg-4 me-lg-2 mb-0">
                Our primary goal is to attain industry leadership by
                consistently driving innovation, embracing sustainability, and
                prioritizing client satisfaction, ensuring our elevator
                solutions remain at the forefront of excellence.
              </p>
              <div className="mt-4 d-flex align-items-center flex-column text-start align-items-lg-start">
                <div>
                  <div className="d-flex align-items-center gap-3 mt-3">
                    <div className="gradient-circle d-flex align-items-center justify-content-center">
                      {tick}
                    </div>
                    <p className="mb-0 ff-arial fs_sm lh-130 opacity-08">
                      Innovate for industry leadership.
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-3">
                    <div className="gradient-circle d-flex align-items-center justify-content-center">
                      {tick}
                    </div>
                    <p className="mb-0 ff-arial fs_sm lh-130 opacity-08">
                      Prioritize sustainability in all operations.
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-3">
                    <div className="gradient-circle d-flex align-items-center justify-content-center">
                      {tick}
                    </div>
                    <p className="mb-0 ff-arial fs_sm lh-130 opacity-08">
                      Ensure exceptional client satisfaction.
                    </p>
                  </div>
                  <div className="d-flex align-items-center gap-3 mt-3">
                    <div className="gradient-circle d-flex align-items-center justify-content-center">
                      {tick}
                    </div>
                    <p className="mb-0 ff-arial fs_sm lh-130 opacity-08">
                      Foster a culture of continuous improvement.
                    </p>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={5} lg={6}>
              <img
                className="image-border-radius w-100"
                src={mdimage}
                alt="Main Director"
              />
            </Col>
          </Row>
        </div>
      </section>
      <img loading="lazy" className="w-100 bottom-wave" src={bottomwave} alt="wave" />
    </>
  );
};

export default CompanyGoal;
