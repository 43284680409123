import React from "react";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const HomeEleAdv = () => {
  return (
    <>
      <img loading="lazy" className="w-100 top-wave" src={topwave} alt="wave" />
      <section className="py-5 bg-grey product-advantage-sec">
        <div className="custom_container container">
          <h2 className="product-page-common-heading text-center">
            Advantages
          </h2>
          <div className="mt-5">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Accessibility and Convenience
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Home elevators offer easy access across floors, catering to
              individuals with mobility challenges and enhancing convenience for
              all residents. Their user-friendly design ensures hassle-free
              movement within your home.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Medical Equipment Accessibility
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Installing a home elevator can increase your property's value,
              making it an attractive investment. This feature adds a touch of
              luxury and exclusivity, appealing to potential buyers and
              enhancing resale opportunities.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Aesthetic Integration
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Home elevators seamlessly blend with your interior design.
              Customizable finishes, cabin styles, and lighting options allow
              you to create a cohesive and visually pleasing elevator that
              complements your home's aesthetics.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Future-Proofing
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Incorporating a home elevator ensures your residence remains
              adaptable to changing mobility needs over time. As family members
              age or situations evolve, this feature guarantees continued
              accessibility and comfort
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Family Safety
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Home elevators prioritize safety with features such as backup
              power, emergency buttons, and secure locking mechanisms. These
              measures ensure worry-free operation and protect your loved ones
              during elevator use.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Comfort and Luxury
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Elevate your lifestyle with the convenience of a home elevator. It
              not only simplifies vertical movement but also adds a touch of
              luxury, making your home feel grander and enhancing your overall
              living experience.
            </p>
          </div>
        </div>
      </section>
      <img loading="lazy" className="w-100 bottom-wave" src={bottomwave} alt="wave" />
    </>
  );
};

export default HomeEleAdv;
