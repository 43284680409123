import React from "react";
import { Col, Row } from "react-bootstrap";
import goodsElevator from "../assets/png/prodgoodsele.png";

const GoodsEle = () => {
  return (
    <section className="py-5">
      <div className="custom-conatiner container">
        <h1 className="ff-baskerville fs_3xl lh-100 product-heading-text-stroke text-center">
          Goods Elevators
        </h1>
        <Row className="mt-5 pt-lg-5 pt-3 align-items-center flex-column-reverse flex-lg-row">
          <Col lg={6}>
            <div className="text-center text-lg-start mt-4">
              <h2 className="product-page-common-heading">
                Cargo Elevator Ease
              </h2>
              <p className="ff-arial clr-light-black-2 lh-150 fs_xsm pe-lg-5 mb-0 mt-3">
                Optimize vertical transportation for goods with JMD Elevators'
                Goods Elevators. Engineered for efficiency, our elevators ensure
                seamless movement of materials in industrial and commercial
                settings. Designed for durability and reliability, they enhance
                workflow and productivity. Trust us to deliver reliable and
                robust goods elevator solutions that meet your specific business
                needs.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="image-div-border-radius overflow-hidden">
              <img loading="lazy"
                className="w-100 image-border-radius transition-300 img-hover"
                src={goodsElevator}
                alt="elevator"
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default GoodsEle;
