import React from "react";
import HomeEle from "../components/HomeEle";
import ProductHeader from "../components/ProductHeader";
import HomeEleAdv from "../components/HomeEleAdv";
import OurCollection from "../components/OurCollection";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";

const HomeElevator = () => {
  return (
    <>
      <ProductHeader />
      <HomeEle />
      <HomeEleAdv />
      <OurCollection />
      <GetInTouch />
      <JmdFooter />
    </>
  );
};

export default HomeElevator;
