import React from "react";
import {
  elevatordoor,
  expertisecardnextarrow,
  expertisecardprevarrow,
} from "../pages/MapData";
import Slider from "react-slick";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const ElevatorsDoor = () => {
  const button = React.useRef();
  var settings = {
    autoplay: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <div className="py-5">
      <img loading="lazy" className={`w-100 top-wave`} src={topwave} alt="wave" />
      <section className="py-5 gallery-sec bg-grey product-advantage-sec">
        <div className="custom-container container">
          <h2 className="product-page-common-heading text-center">
            Elevators Door
          </h2>
          <Slider className="pt-5 pb-2" {...settings} ref={button}>
            {elevatordoor.map((val) => {
              return (
                <div className="image-border-radius px-2 overflow-hidden">
                  <img loading="lazy"
                    className="w-100 img-hover transition-300"
                    src={val.doorimg}
                    alt="elevator cabin"
                  />
                </div>
              );
            })}
          </Slider>
          <div className="d-flex justify-content-center gap-3 mt-5">
            <button
              className="prev-next-btn d-flex align-items-center justify-content-center transition-300"
              onClick={() => button.current.slickPrev()}
            >
              {expertisecardnextarrow}
            </button>
            <button
              className="prev-next-btn d-flex align-items-center justify-content-center transition-300"
              onClick={() => button.current.slickNext()}
            >
              {expertisecardprevarrow}
            </button>
          </div>
        </div>
      </section>
      <img loading="lazy" className={`w-100 bottom-wave`} src={bottomwave} alt="wave" />
    </div> 
  );
};

export default ElevatorsDoor;
