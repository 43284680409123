import React from "react";
import { Col, Row } from "react-bootstrap";
import goodscumpassenger from "../assets/png/prodgoodscumpassengerlift.png";

const GoodsCumPassengerEle = () => {
  return (
    <section className="py-5">
      <div className="custom-conatiner container">
        <h1 className="ff-baskerville fs_3xl lh-100 product-heading-text-stroke text-center">
          Goods Cum Passenger Elevator
        </h1>
        <Row className="mt-5 pt-lg-5 pt-3 align-items-center flex-column-reverse flex-lg-row">
          <Col lg={6}>
            <div className="text-center text-lg-start mt-4">
              <h2 className="product-page-common-heading">
                Multi-Use Elevator Solutions
              </h2>
              <p className="ff-arial clr-light-black-2 lh-150 fs_xsm pe-lg-5 mb-0 mt-3">
                Our "Goods cum Passenger Lift," also known as a "Multi-Use
                Elevator," streamlines vertical transportation needs. This
                dual-purpose system efficiently moves goods and passengers
                within a compact design, optimizing space in diverse settings
                like offices and residences. It ensures convenience, safety, and
                reliability in one package, catering to those seeking a
                versatile vertical mobility solution.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="image-div-border-radius overflow-hidden">
              <img loading="lazy"
                className="w-100 image-border-radius transition-300 img-hover"
                src={goodscumpassenger}
                alt="elevator"
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default GoodsCumPassengerEle;
