import React from "react";
import { Col, Row } from "react-bootstrap";
import team from "../assets/png/staff.png";

const ExperiencedStaf = () => {
  return (
    <section className="py-5">
      <div className="custom-container container">
        <Row className="align-items-center justify-content-between flex-column-reverse flex-lg-row">
          <Col
            className="pe-lg-5 pe-xl-0 text-center text-lg-start mt-4 mt-lg-0"
            xl={6}
            lg={6}
          >
            <h2 className="product-page-common-heading">Experienced Staff</h2>
            <p className="mb-0 ff-arial opacity-07 lh-150 fs_xsm">
              Our team comprises experienced professionals with in-depth
              knowledge of elevator technology. Their expertise ensures
              precision in installations, maintenance, and service contracts,
              providing you with top-tier solutions and peace of mind. We are
              proud of our skilled staff, dedicated to elevating your vertical
              mobility experience.
            </p>
          </Col>
          <Col className="text-center" xl={5} lg={6}>
            <img loading="lazy" className="team" src={team} alt="employee" />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ExperiencedStaf;
