import React from "react";
import eledoor from "../assets/png/elevatorDoor.png";
import { Col, Row } from "react-bootstrap";

const JmdHistory = () => {
  return (
    <section className="py-5">
      <div className="custom_container container pb-lg-5">
        <h1 className="ff-baskerville fs_3xl lh-100 product-heading-text-stroke text-center">
          About JMD Elevators
        </h1>
        <Row className="align-items-center mt-5 pt-5">
          <Col xl={5} lg={6}>
            <div className="text-center">
              <img loading="lazy"
                className="about-us-sec-img"
                src={eledoor}
                alt="elevator door"
              />
            </div>
          </Col>
          <Col className="ps-xl-5 mt-4 pt-2" xl={7} lg={6}>
            <div className="ps-lg-4 text-center text-lg-start">
              <h2 className="common-heading-class mb-2">
                History of JMD Elevators
              </h2>
              <p className="ff-arial lh-150 fs_xsm opacity-07 pt-1 pe-xl-4 me-xl-2">
                With 13 years in elevators, we've forged trust and a robust
                reputation, catering to 100+ clients. Our journey commenced with
                a fervor for vertical mobility, consistently surpassing
                expectations through innovative solutions. Our achievements
                pivot on an unyielding pledge to safety, efficiency, and
                dependability in both installations and service contracts. As we
                look ahead, our commitment to excellence remains unwavering. We
                eagerly embrace the opportunity to serve both our steadfast
                clients and newcomers to our services. To all who have been a
                part of our journey, we extend our heartfelt gratitude. Here's
                to many more years of elevating your expectations in vertical
                mobility.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default JmdHistory;
