import React from "react";
import { Col, Row } from "react-bootstrap";
import glassElevator from "../assets/png/prodglassele.png";

const GlassEle = () => {
  return (
    <section className="py-5">
      <div className="custom-conatiner container">
        <h1 className="ff-baskerville fs_3xl lh-100 product-heading-text-stroke text-center">
          Glass Elevators
        </h1>
        <Row className="mt-5 pt-lg-5 pt-3 align-items-center flex-column-reverse flex-lg-row">
          <Col lg={6}>
            <div className="text-center text-lg-start mt-4">
              <h2 className="product-page-common-heading">
                Transparent Elevate
              </h2>
              <p className="ff-arial clr-light-black-2 lh-150 fs_xsm pe-lg-5 mb-0 mt-3">
                Discover elegance and innovation with JMD Elevators' Glass
                Elevators. Bringing sophistication to vertical mobility, our
                elevators feature stunning glass designs that add a touch of
                modernity to any space. Built for safety and aesthetics, they
                create a unique visual experience while ensuring smooth transit.
                Trust us to provide reliable and stylish glass elevator
                solutions that elevate the atmosphere of your building.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="image-div-border-radius overflow-hidden">
              <img loading="lazy"
                className="w-100 image-border-radius transition-300 img-hover"
                src={glassElevator}
                alt="elevator"
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default GlassEle;
