import React from "react";
import JmdNavbar from "./JmdNavbar";

const AboutUsPageHeader = () => {
  return (
    <section className="products-header-bg-vh products-header-bg header-sec">
      <JmdNavbar
        bg_transparent="bg-transparent"
        bg_linear="bg-linear"
        prodlogonone="d-none"
        prodlogoblock="d-block"
        navlinkclr="text-white"
        aboutopacity08="opacity-08"
        abooutopacity100="opacity-100"
        about="about"
        cntctbtn="product-page-button"
        menuicon="white-bar"
      />
    </section>
  );
};

export default AboutUsPageHeader;
