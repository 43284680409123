import React from "react";
import { Link } from "react-router-dom";
import { useCountUp } from "use-count-up";
const JmdHero = () => {
  const { value: value1 } = useCountUp({
    isCounting: true,
    end: 12,
    duration: 3,
  });

  // Define the second count-up animation
  const { value: value2 } = useCountUp({
    isCounting: true,
    end: 100,
    duration: 5,
  });

  // Define the third count-up animation
  const { value: value3 } = useCountUp({
    isCounting: true,
    start: 700,
    end: 1000,
    duration: 6,
  });
  return (
    <section className="hero-sec d-flex align-items-center justify-content-center flex-grow-1 py-5 text-center text-sm-start">
      <div className="custom_container container mx-auto py-lg-5 py-xl-0">
        <h1 className=" ff-baskerville fs_3xl lh-100 heading-text-stroke">
          Jai Maa Durga Elevators
        </h1>
        <p className="clr-light-black ff-arial para-width fs_xsm">
          At JMD Elevators, quality and safety are not just priorities; they are
          the pillars of our commitment to superior vertical transportation.
        </p>
        <Link to="/product">
          <button className="common-button ff-petrona fs_md">
            Explore Elevators
          </button>
        </Link>
        <div className="d-flex align-items-center justify-content-center justify-content-sm-start gap-lg-5 gap-sm-4 gap-3 mt-5">
          <div className="d-flex align-items-center flex-column">
            <div>
              <span className="ff-prata countup-fs">{value1}</span>
              <span className="ff-poppins plus countup-fs">+</span>
            </div>
            <p className="mb-0 ff-arial lh-150 clr-light-black">
              Services Provided
            </p>
          </div>

          <div className="countings-vertical-line"></div>
          <div className="d-flex align-items-center flex-column">
            <div>
              <span className="ff-prata countup-fs">{value2}</span>
              <span className="ff-poppins plus countup-fs">+</span>
            </div>
            <p className="mb-0 ff-arial lh-150 clr-light-black">
              Cities Covered
            </p>
          </div>
          <div className="countings-vertical-line"></div>
          <div className="d-flex align-items-center flex-column">
            <div>
              <span className="ff-prata countup-fs">{value3}</span>
              <span className="ff-poppins plus countup-fs">+</span>
            </div>
            <p className="mb-0 ff-arial lh-150 clr-light-black">
              Happy CLients
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default JmdHero;
