import React from "react";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const HospitalEleAdv = () => {
  return (
    <>
      <img loading="lazy" className="w-100 top-wave" src={topwave} alt="wave" />
      <section className="py-5 bg-grey product-advantage-sec">
        <div className="custom_container container">
          <h2 className="product-page-common-heading text-center">
            Advantages
          </h2>
          <div className="mt-5">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Efficient Patient Transport
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Our hospital elevators prioritize quick and safe patient
              transportation, minimizing waiting times and enhancing overall
              healthcare efficiency. Patients receive prompt care while medical
              staff can move swiftly between floors, ensuring seamless
              operations
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Medical Equipment Accessibility
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Hospital elevators are designed to accommodate medical equipment
              of varying sizes, facilitating smooth transportation between
              floors. This ensures timely availability of critical tools for
              medical procedures, contributing to optimal patient care.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Infection Control
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Hospital elevators are equipped with features that aid infection
              control, such as touchless buttons and easy-to-clean surfaces.
              This safeguards patients, staff, and visitors from potential
              health risks, creating a safer healthcare environment
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Enhanced Safety Features
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Safety is paramount. Industrial elevators are equipped with
              advanced safety mechanisms, ensuring secure transportation of
              materials and personnel. These features reduce risks and adhere to
              strict safety regulations
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Reduced Noise and Vibration
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Hospital elevators are engineered for minimal noise and vibration,
              creating a peaceful and comfortable environment for patients. The
              smooth ride contributes to a calming atmosphere within the
              healthcare facility
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Regulatory Compliance
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Our hospital elevators adhere to stringent industry standards and
              regulations, ensuring patient safety and operational compliance.
              This commitment underscores our dedication to providing elevators
              that meet the highest healthcare standards
            </p>
          </div>
        </div>
      </section>
      <img loading="lazy" className="w-100 bottom-wave" src={bottomwave} alt="wave" />
    </>
  );
};

export default HospitalEleAdv;
