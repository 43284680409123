import React from "react";
import {
  clientsCarddata,
  expertisecardnextarrow,
  expertisecardprevarrow,
} from "../pages/MapData";
import Slider from "react-slick";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const OurClients = () => {
  const button = React.useRef();
  var settings = {
    autoplay: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <>
      <img loading="lazy" className="w-100 top-wave" src={topwave} alt="wave" />
      <section className="py-5 bg-grey sec-our-clients">
        <div className="custom_container container">
          <h2 className="common-heading-class text-center">
            Words from Our Clients
          </h2>
          <Slider className="py-2 mt-5" {...settings} ref={button}>
            {clientsCarddata.map((val) => {
              return (
                <div className="px-2">
                  <div className="our-clients-card px-3 py-4 h-100 transition-300">
                    <div className="d-flex align-items-center gap-3">
                      <img loading="lazy" width={50} src={val.clientLogo} alt="logo" />
                      <div>
                        <h5 className="mb-0 ff-arial fs_md lh-145 clr-black">
                          {val.compname}
                        </h5>
                        <p className=" ff-open lh-150 fs_xsm opacity-07 mb-0">
                          {val.revdate}
                        </p>
                      </div>
                    </div>
                    <div className="mt-2">{val.rating}</div>
                    <p className="mb-0 ff-arial fs_xsm fst-italic lh-160 mt-3 opacity-07 clr-black">
                      {val.review}
                    </p>
                  </div>
                </div>
              );
            })}
          </Slider>
          <div className="d-flex justify-content-center gap-3 mt-5">
            <button
              className="prev-next-btn d-flex align-items-center justify-content-center transition-300"
              onClick={() => button.current.slickPrev()}
            >
              {expertisecardnextarrow}
            </button>
            <button
              className="prev-next-btn d-flex align-items-center justify-content-center transition-300"
              onClick={() => button.current.slickNext()}
            >
              {expertisecardprevarrow}
            </button>
          </div>
        </div>
      </section>
      <img loading="lazy" className="w-100 bottom-wave mb-5" src={bottomwave} alt="wave" />
    </>
  );
};

export default OurClients;
