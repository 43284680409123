import React from "react";
import { Row } from "react-bootstrap";
import { arrow, offeringCarddata } from "../pages/MapData";
import { Link } from "react-router-dom";

const OurOffering = () => {
  return (
    <section className="py-5">
      <div className="custom_container container py-5">
        <h2 className="common-heading-class text-center">
          Elevating Experiences: Our Offerings
        </h2>
        <Row className="mt-4 pt-xl-5 justify-content-center">
          {offeringCarddata.map((val) => {
            return (
              <div className="col-lg-4 col-sm-6 mt-4">
                <Link className="text-black" to={val.linkpath}>
                  <div className="our-offering-cards transition-300 h-100 cursor_pointer d-flex flex-column justify-content-between">
                    <div className="overflow-hidden image-div-border-radius">
                      <img loading="lazy"
                        className="w-100 transition-300"
                        src={val.img}
                        alt="elevators"
                      />
                    </div>
                    <h4 className="mb-0 mt-3 pt-1 ff-merri fs_lg">
                      {val.head}
                    </h4>
                    <p className="mb-0 mt-2 pt-1 ff-arial fs_xsm lh-150 opacity-07">
                      {val.para}
                    </p>
                    <div className="d-flex align-items-center gap-2 my-2 pt-1">
                      <p className="ff-arial fs_md mb-0 text-read-more">
                        {val.readmore}
                      </p>
                      {val.arrow}
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </Row>
      </div>
    </section>
  );
};

export default OurOffering;
