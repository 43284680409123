import React from "react";
import ProductHeader from "../components/ProductHeader";
import HospitalEle from "../components/HospitalEle";
import HospitalEleAdv from "../components/HospitalEleAdv";
import OurCollection from "../components/OurCollection";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";

const HospitalElevators = () => {
  return (
    <>
      <ProductHeader />
      <HospitalEle />
      <HospitalEleAdv />
      <OurCollection />
      <GetInTouch />
      <JmdFooter />
    </>
  );
};

export default HospitalElevators;
