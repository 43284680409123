import React from "react";
import { Col, Row } from "react-bootstrap";
import logo from "../assets/svg/whitelogo.svg";
import { facebook, instagram, twitter, whatsapp } from "../pages/MapData";
import { Link } from "react-router-dom";

const JmdFooter = () => {
  let currentYear = new Date().getFullYear();
  return (
    <section className="pt-5 pb-3 clr-black-2 mt-5 footer">
      <div className="custom_container container">
        <Row className="justify-content-between">
          <Col xl={4} lg={6}>
            <img loading="lazy" width={230} src={logo} alt="logo" />
            <p className="mb-0 mt-4 ff-arial fs_xsm lh-150 opacity-07 text-white pe-lg-4 pe-xl-0">
              Experience seamless vertical mobility with our expert elevator
              installation and annual service contracts. Elevate your building's
              reliability and performance with us.
            </p>
            <div className="d-flex align-items-center gap-3 mt-4">
              <a
                className="social-sites-logo-bg d-flex align-items-center justify-content-center"
                href="https://www.facebook.com/jaimaadurga.elevators?mibextid=ZbWKwL"
                target="blank"
              >
                {facebook}
              </a>
              <a
                className="social-sites-logo-bg d-flex align-items-center justify-content-center"
                href="https://www.instagram.com/jaimaadurgaelevators/"
                target="blank"
              >
                {instagram}
              </a>
              <a
                className="social-sites-logo-bg d-flex align-items-center justify-content-center"
                href="#"
                target="blank"
              >
                {twitter}
              </a>
              <a
                className="social-sites-logo-bg d-flex align-items-center justify-content-center"
                href="https://wa.me/+91 9697000631/?text=Hello%20,%20I%20have%20a%20question
                "
                target="blank"
              >
                {whatsapp}
              </a>
            </div>
          </Col>
          <Col className="mt-5 mt-lg-0" xl={7} lg={6}>
            <Row>
              <Col sm={4} xs={6}>
                <ul className="ps-0 ps-lg-4">
                  <li className="ff-merri lh-130 fs_md text-white">About</li>
                  <li className="mt-4">
                    <Link
                      to="/aboutus"
                      className="ff-dmsans text-white fs_xsm opacity-07"
                    >
                      About US
                    </Link>
                  </li>
                  <li className="mt-2">
                    <a
                      className="ff-dmsans text-white fs_xsm opacity-07"
                      href="#"
                    >
                      Job
                    </a>
                  </li>
                  <li className="mt-2">
                    <Link
                      to="/service"
                      className="ff-dmsans text-white fs_xsm opacity-07"
                    >
                      Service
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col sm={4} xs={6}>
                <ul className="ps-0 ps-lg-4">
                  <li className=" ff-merri lh-130 fs_md text-white">Support</li>
                  <li className="mt-4">
                    <a
                      className="ff-dmsans text-white fs_xsm opacity-07"
                      href="tel:+91 9697000632"
                    >
                      Call Us
                    </a>
                  </li>
                  <li className="mt-2">
                    <a
                      className="ff-dmsans text-white fs_xsm opacity-07"
                      href="https://wa.me/+91 9697000631/?text=Hello%20,%20I%20have%20a%20question"
                    >
                      Whatsapp
                    </a>
                  </li>
                  <li className="mt-2">
                    <a
                      className="ff-dmsans text-white fs_xsm opacity-07"
                      href="mailto:jmdsales910@gmail.com"
                    >
                      Email
                    </a>
                  </li>
                </ul>
              </Col>
              <Col className="mt-4 mt-sm-0" sm={4} xs={6}>
                <ul className="ps-0 ps-lg-4">
                  <li className="ff-merri lh-130 fs_md text-white">Address</li>
                  <li className="mt-4 address">
                    <a
                      className="ff-dmsans text-white fs_xsm opacity-07"
                      href="https://goo.gl/maps/1WnkA3gCdWd7MauC9"
                      target="blank"
                    >
                      Dss 367 2nd floor sec 16-17 Hisar, Haryana
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="hr_line"></div>
      <p className="mb-0 mt-3 text-center text-white ff-poppins fs_xxsm lh-120 opacity-07">
        ©JmdElevators {currentYear}, All Rights Reserved
      </p>
    </section>
  );
};

export default JmdFooter;
