import React from "react";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const GlassEleAdv = () => {
  return (
    <>
      <img loading="lazy" className="w-100 top-wave" src={topwave} alt="wave" />
      <section className="py-5 bg-grey product-advantage-sec">
        <div className="custom_container container">
          <h2 className="product-page-common-heading text-center">
            Advantages
          </h2>
          <div className="mt-5">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">Stylish Look</h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Elevate your building's charm with glass elevators that seamlessly
              integrate contemporary aesthetics. Their sleek and modern design
              adds a touch of elegance, enhancing the architectural appeal of
              your space.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Natural Brightness
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Enjoy the beauty of natural light flooding your surroundings.
              Glass elevators create a sunlit atmosphere, minimizing the need
              for additional artificial lighting. This not only enhances energy
              efficiency but also creates a warm and inviting ambiance.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              360-Degree Views
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Embark on a captivating visual journey with glass elevators that
              offer panoramic vistas. As you ascend or descend, revel in the
              breathtaking 360-degree views that add a unique and memorable
              element to your transportation experience.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Sense of Openness
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Glass elevators go beyond function, creating an illusion of
              openness. By visually expanding the available space, they make
              even compact areas feel more spacious, contributing to a
              comfortable and inviting environment.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">Easy Match</h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Versatility meets design with glass elevators that effortlessly
              complement diverse architectural styles. Tailor the elevator's
              appearance with customizable finishes, ensuring a seamless blend
              that harmonizes with your overall design scheme.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Impressive Impact
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Make a lasting impression on visitors and occupants alike with the
              striking impact of glass elevators. Their modern and transparent
              look serves as a captivating centerpiece, adding a sense of wonder
              and enhancing the overall aesthetic appeal of your space.
            </p>
          </div>
        </div>
      </section>
      <img loading="lazy" className="w-100 bottom-wave" src={bottomwave} alt="wave" />
    </>
  );
};

export default GlassEleAdv;
