import React from "react";
import ProductHeader from "../components/ProductHeader";
import IndustrialEle from "../components/IndustrialEle";
import IndustrialEleAdv from "../components/IndustrialEleAdv";
import OurCollection from "../components/OurCollection";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";

const IndustrialElevator = () => {
  return (
    <>
      <ProductHeader />
      <IndustrialEle />
      <IndustrialEleAdv />
      <OurCollection />
      <GetInTouch />
      <JmdFooter />
    </>
  );
};

export default IndustrialElevator;
