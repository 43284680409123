import React from "react";
import JmdHeader from "../components/JmdHeader";
import AboutUs from "../components/AboutUs";
import OurServices from "../components/OurServices";
import ElevatorExpertise from "../components/ElevatorExpertise";
import WhyChooseUs from "../components/WhyChooseUs";
import OurOffering from "../components/OurOffering";
import GetInTouch from "../components/GetInTouch";
import OurCollection from "../components/OurCollection";
import OurClients from "../components/OurClients";
import JmdFooter from "../components/JmdFooter";

const LandingPage = () => {
  return (
    <>
      <JmdHeader />
      <AboutUs />
      <OurServices />
      <ElevatorExpertise />
      <WhyChooseUs />
      <OurOffering />
      <GetInTouch />
      <OurCollection />
      <OurClients />
      <JmdFooter />
    </>
  );
};

export default LandingPage;
