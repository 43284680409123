import React from "react";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const EleLopCopAdv = () => {
  return (
    <>
      <img loading="lazy" className="w-100 top-wave" src={topwave} alt="wave" />
      <section className="py-5 bg-grey product-advantage-sec">
        <div className="custom_container container">
          <h2 className="product-page-common-heading text-center">
            Advantages
          </h2>
          <div className="mt-5">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Quick Elevator Access
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Innovative Landing Operating Panel (LOP) and Cabin Operating Panel
              (COP) systems revolutionize elevator use by enabling rapid calls
              and controls. This results in notably shorter waiting times,
              improving vertical movement within buildings.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              User-Friendly
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              LOP and COP systems feature clear buttons and interfaces, ensuring
              effortless elevator interaction for all users. The straightforward
              design enhances usability, catering to passengers with different
              levels of familiarity with elevator technology.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Enhanced Safety
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              The inclusion of emergency call buttons within the COP provides an
              additional layer of security, allowing passengers to swiftly alert
              authorities in unforeseen situations.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Crowd Management
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              These systems strategically distribute passengers across
              elevators, preventing overcrowding and ensuring a comfortable
              journey. The controlled passenger load contributes to smoother
              operation and a more pleasant ride experience.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Customized Experience
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              LOP and COP interfaces can be adjusted to match the specific floor
              layout and visual aesthetics of the building. This personalized
              approach creates a unified atmosphere and familiarity for users.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Informed Efficiency
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              With the ability to collect and analyze data on elevator usage,
              these systems offer valuable insights to building managers. This
              data-driven approach empowers managers to optimize maintenance
              schedules, streamline operations, and improve overall efficiency,
              ensuring consistently smooth elevator service.
            </p>
          </div>
        </div>
      </section>
      <img loading="lazy" className="w-100 bottom-wave" src={bottomwave} alt="wave" />
    </>
  );
};

export default EleLopCopAdv;
