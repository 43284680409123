import React from "react";
import { Col, Row } from "react-bootstrap";
import eledoor from "../assets/png/elevatorDoor.png";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <section className="py-5">
      <div className="custom_container container py-xl-5 pt-4">
        <Row className="align-items-center">
          <Col xl={5} lg={6}>
            <div className="text-center">
              <img
              loading="lazy"
                className="about-us-sec-img"
                src={eledoor}
                alt="elevator door"
              />
            </div>
          </Col>
          <Col className="ps-xl-5 mt-4 pt-2" xl={7} lg={6}>
            <div className="ps-lg-4 text-center text-lg-start">
              <h2 className="common-heading-class mb-2">About Us</h2>
              <p className="ff-arial lh-150 fs_xsm opacity-07 pt-1 pe-xl-4 me-xl-2">
                JMD Elevators is an engineering company which manufactures and
                supplies Elevators, Elevator components. The Company has
                identified a niche in the Industry and specializes in creating
                and designing exclusive Elevators that cater to your exact
                requirement. The management of the Company makes a promise to
                ensure safety, reliability and efficiency of every Elevator -
                without compromise.
              </p>
              <div className="pt-sm-4 pt-2">
                <Link to="/aboutus">
                  <button className="common-button ff-petrona fs_md mt-lg-2">
                    Learn More
                  </button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AboutUs;
