import React from "react";
import { Col, Row } from "react-bootstrap";

const MissionAndVision = () => {
  return (
    <section className="py-5">
      <div className="custom_container container pt-lg-5  pt-3">
        <Row>
          <Col md={6}>
            <div className="our-mission p-4 h-100">
              <h2 className=" ff-baskerville lh-100 fs_4x text-white mb-md-4">
                Our Mission
              </h2>
              <p className="mb-0 text-white opacity-08 lh-150 fs_xsm ff-arial">
                Our mission is to elevate urban living by delivering
                cutting-edge elevator solutions that enhance mobility, safety,
                and efficiency, while minimizing environmental impact and
                exceeding client expectations.
              </p>
            </div>
          </Col>
          <Col md={6}>
            <div className="our-vision p-4 h-100 mt-4 mt-md-0">
              <h2 className=" ff-baskerville lh-100 fs_4x text-black mb-md-4">
                Our Vision
              </h2>
              <p className="mb-0 text-black opacity-08 lh-150 fs_xsm ff-arial">
                Our vision is to be the global leader in elevator technology,
                setting new standards for urban mobility. We aim to
                revolutionize vertical transportation, making it smarter, more
                sustainable, and accessible for all.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default MissionAndVision;
