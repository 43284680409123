import React from "react";
import { Col, Row } from "react-bootstrap";
import teamworking from "../assets/png/elevatorinstallation.png";

const IndustryExperience = () => {
  return (
    <section className="py-5">
      <div className="custom-container container">
        <Row className="align-items-center justify-content-between flex-column-reverse flex-lg-row">
          <Col
            className="pe-lg-5 pe-xl-0 text-center text-lg-start mt-4 mt-lg-0"
            xl={6}
            lg={6}
          >
            <h2 className="product-page-common-heading">
              15+ Experience In Industry
            </h2>
            <p className="mb-0 ff-arial opacity-07 lh-150 fs_xsm">
              With over a decade of experience in the industry, our track record
              reflects our deep expertise and understanding of elevator
              technology. This extensive experience equips us to deliver
              exceptional elevator solutions that meet your needs and exceed
              expectations.
            </p>
          </Col>
          <Col className="text-center" xl={5} lg={6}>
            <img loading="lazy"
              className="team"
              src={teamworking}
              alt="elevatorinstallation"
            />
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default IndustryExperience;
