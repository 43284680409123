import React from "react";
import ProductHeader from "../components/ProductHeader";
import GoodsCumPassengerEle from "../components/GoodsCumPassengerEle";
import GoodsPassEleAdv from "../components/GoodsPassEleAdv";
import OurCollection from "../components/OurCollection";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";

const GoodscumPassenger = () => {
  return (
    <>
      <ProductHeader />
      <GoodsCumPassengerEle />
      <GoodsPassEleAdv />
      <OurCollection />
      <GetInTouch />
      <JmdFooter />
    </>
  );
};

export default GoodscumPassenger;
