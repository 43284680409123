import React from "react";
import ProductHeader from "../components/ProductHeader";
import CommercialEle from "../components/CommercialEle";
import CommercialEleAdv from "../components/CommercialEleAdv";
import OurCollection from "../components/OurCollection";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";
const CommercialElevator = () => {
  return (
    <>
      <ProductHeader />
      <CommercialEle />
      <CommercialEleAdv />
      <OurCollection />
      <GetInTouch />
      <JmdFooter />
    </>
  );
};

export default CommercialElevator;
