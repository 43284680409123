import React from "react";
import chairElevator from "../assets/png/prodchairele.png";
import { Col, Row } from "react-bootstrap";

const ChairEle = () => {
  return (
    <section className="py-5">
      <div className="custom-conatiner container">
        <h1 className="ff-baskerville fs_3xl lh-100 product-heading-text-stroke text-center">
          Chair Elevators
        </h1>
        <Row className="mt-5 pt-lg-5 pt-3 align-items-center flex-column-reverse flex-lg-row">
          <Col lg={6}>
            <div className="text-center text-lg-start mt-4">
              <h2 className="product-page-common-heading">
                Chairlift Elegance
              </h2>
              <p className="ff-arial clr-light-black-2 lh-150 fs_xsm pe-lg-5 mb-0 mt-3">
                Experience enhanced accessibility with JMD Elevators' Chair
                Elevators. Tailored for comfort and convenience, our elevators
                make movement easy for those with mobility challenges. Designed
                with user-friendly features, they seamlessly blend into any
                space. Trust us to provide reliable and safe chair elevator
                solutions, improving independence and quality of life.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="image-div-border-radius overflow-hidden">
              <img loading="lazy"
                className="w-100 image-border-radius transition-300 img-hover"
                src={chairElevator}
                alt="elevator"
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ChairEle;
