import React from "react";
import { Col, Row } from "react-bootstrap";
import commercialElevator from "../assets/png/prodcommele.png";

const CommercialEle = () => {
  return (
    <section className="py-5">
      <div className="custom-conatiner container">
        <h1 className="ff-baskerville fs_3xl lh-100 product-heading-text-stroke text-center">
          Commercial Elevators
        </h1>
        <Row className="mt-5 pt-lg-5 pt-3 align-items-center flex-column-reverse flex-lg-row">
          <Col lg={6}>
            <div className="text-center text-lg-start mt-4">
              <h2 className="product-page-common-heading">
                Elevating Your Business
              </h2>
              <p className="ff-arial clr-light-black-2 lh-150 fs_xsm pe-lg-5 mb-0 mt-3">
                JMD Elevators excels in high-performance Commercial Elevator
                solutions, blending efficiency and safety seamlessly into
                offices and retail spaces. Our elevators integrate
                state-of-the-art technology, ensuring smooth transit and
                enhancing user experience. Designed for bustling environments,
                they handle traffic demands effortlessly. With a focus on
                aesthetics and functionality, JMD Elevators modernizes and
                enhances vertical mobility for commercial spaces
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="image-div-border-radius overflow-hidden">
              <img loading="lazy"
                className="w-100 image-border-radius transition-300 img-hover"
                src={commercialElevator}
                alt="elevator"
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default CommercialEle;
