import React from "react";
import { Col, Row } from "react-bootstrap";
import elevatorLopcop from "../assets/png/prodelelopcop.png";

const EleLopCop = () => {
  return (
    <section className="py-5">
      <div className="custom-conatiner container">
        <h1 className="ff-baskerville fs_3xl lh-100 product-heading-text-stroke text-center">
          Lop & Cop
        </h1>
        <Row className="mt-5 pt-lg-5 pt-3 align-items-center flex-column-reverse flex-lg-row">
          <Col lg={6}>
            <div className="text-center text-lg-start mt-4">
              <h2 className="product-page-common-heading">
                Luxurious Control Experience
              </h2>
              <p className="ff-arial clr-light-black-2 lh-150 fs_xsm pe-lg-5 mb-0 mt-3">
                Experience seamless vertical transit with JMD Elevators'
                advanced elevator control systems. Our Landing Operating Panel
                (LOP) simplifies landing calls, allowing efficient command for
                elevator arrivals. Inside the cabin, our Cabin Operating Panel
                (COP) offers intuitive floor selection and door controls,
                ensuring a comfortable journey. Trust in our comprehensive
                solutions for both LOP and COP, enhancing the overall elevator
                experience with user-friendly functionality.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="image-div-border-radius overflow-hidden">
              <img loading="lazy"
                className="w-100 image-border-radius transition-300 img-hover"
                src={elevatorLopcop}
                alt="elevator"
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default EleLopCop;
