import React from "react";
import { Col, Row } from "react-bootstrap";
import { callIcon, mailIcon, vrline } from "../pages/MapData";
import receptionist from "../assets/png/receptionist.png";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const GetInTouch = (props) => {
  return (
    <>
      <img loading="lazy"
        className={`w-100 top-wave ${props.wave}`}
        src={topwave}
        alt="wave"
      />
      <section
        id="contactUs"
        className={`py-5 bg-grey sec-get-in-touch ${props.aboutusbg}`}
      >
        <div className="custom_container container">
          <Row className="align-items-center flex-column-reverse flex-lg-row text-center text-lg-start">
            <Col className="mt-4" xl={7} lg={7}>
              <div>
                <div className="pe-xl-5 me-xl-5">
                  <h2 className="common-heading-class mb-0">Get in Touch</h2>
                  <p className="mb-0 ff-arial fs-xsm opacity-07 lh-150 pe-xl-5 me-lg-5 pt-1 mt-2">
                    Transforming spaces through elevators is our expertise. From
                    concept to installation, our team is dedicated to crafting
                    elevator solutions that enhance accessibility and
                    aesthetics. Connect with us today to start the journey of
                    transforming your space.
                  </p>
                </div>
                <div className="d-flex flex-column align-items-center align-items-lg-start">
                  <div>
                    <div className="mt-4 pt-3 d-flex align-items-center justify-content-lg-start gap-xl-3 gap-lg-1 gap-3">
                      <div className="bg-blue-circle d-flex align-items-center justify-content-center">
                        {callIcon}
                      </div>
                      <div className="d-md-flex align-items-center gap-3 gap-lg-1 gap-xl-3">
                        <a
                          className=" ff-prata lh-130 fs-xsm ls-1-68 text-black opacity-09 call-number"
                          href="tel:+91 9697000631"
                        >
                          +91 9697000631
                        </a>
                        <div>{vrline}</div>
                        <a
                          className=" ff-prata lh-130 fs-xsm ls-1-68 text-black opacity-09 call-number"
                          href="tel:+91 9697000632"
                        >
                          +91 9697000632
                        </a>
                        <div>{vrline}</div>
                        <a
                          className=" ff-prata lh-130 fs-xsm ls-1-68 text-black opacity-09 call-number"
                          href="tel:+91 7419561001"
                        >
                          +91 7419561001
                        </a>
                      </div>
                    </div>
                    <div className="mt-3 pt-1 d-flex align-items-center justify-content-lg-start gap-4">
                      <div className="bg-blue-circle d-flex align-items-center justify-content-center">
                        {mailIcon}
                      </div>
                      <a
                        className="ff-baskerville fx_md lh-130 ls-2-1 opacity-09 text-black mail-text"
                        href="mailto:jmdsales910@gmail.com"
                      >
                        jmdsales910@gmail.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={5} lg={5}>
              <img loading="lazy"
                className="receptionist"
                src={receptionist}
                alt="receptionist"
              />
            </Col>
          </Row>
        </div>
      </section>
      <img loading="lazy"
        className={`w-100 bottom-wave ${props.wave}`}
        src={bottomwave}
        alt="wave"
      />
    </>
  );
};

export default GetInTouch;
