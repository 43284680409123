import React, { useEffect } from "react";
import gsap from "gsap";
import logo from "../assets/png/Group 1.png";

const PreLoader = () => {
  const value = "Jai Maa Durga Elevators";
  const value2 = "Rise Above With Us";
  const newArr = value.split(" ").map((obj) => obj.split(""));
  const newArr2 = value2.split(" ").map((obj) => obj.split(""));
  useEffect(() => {
    gsap.fromTo(
      ".text_split_child_appendix-img",
      {
        ease: "elastic.out(.6, 0.3)",
        y: -60,
        stagger: 0.08,
        autoAlpha: 0,
        duration: 1,
        delay: 1,
      },
      {
        ease: "elastic.out(.6, 0.3)",
        y: 0,
        stagger: 0.08,
        autoAlpha: 1,
        duration: 1,
        delay: 1,
      }
    );
    gsap.fromTo(
      ".text_split_child_appendix",
      {
        ease: "elastic.out(.6, 0.3)",
        y: -60,
        stagger: 0.08,
        autoAlpha: 0,
        duration: 0.4,
        delay: 1,
      },
      {
        ease: "elastic.out(.6, 0.3)",
        y: 0,
        stagger: 0.08,
        autoAlpha: 1,
        duration: 0.4,
        delay: 1,
      }
    );
    gsap.fromTo(
      ".text_split_child_appendix-2",
      {
        ease: "elastic.out(.6, 0.3)",
        y: -60,
        stagger: 0.08,
        autoAlpha: 0,
        duration: 0.4,
        delay: 1,
      },
      {
        ease: "elastic.out(.6, 0.3)",
        y: 0,
        stagger: 0.08,
        autoAlpha: 1,
        duration: 0.4,
        delay: 1,
      }
    );
    gsap.fromTo(
      ".text_split_child_appendix-3",
      {
        ease: "elastic.out(.6, 0.3)",
        stagger: 0,
        autoAlpha: 0,
        duration: 12,
        delay: 1,
      },
      {
        ease: "elastic.out(.6, 0.3)",

        stagger: 1,
        autoAlpha: 1,
        duration: 12,
        delay: 1,
      }
    );
  }, []);

  return (
    <div>
      <section className="bg-white vh-100 d-flex flex-column justify-content-center align-items-center px-2">
        <div className="d-flex align-items-center gap-3">
          <div className="">
            <img loading="lazy"
              className="text_split_child_appendix-img preloader-logo"
              src={logo}
              alt="logo"
            />
          </div>
          <div className="d-flex flex-column gap-1 gap-lg-0 mt-2 pt-1 mt-lg-0 pt-lg-0">
            <h1 className="text-black mb-0 ff-raleway fs-preloader-jmd">
              {newArr.map((obj, i) => (
                <span key={i} className="add_clr heroText_width">
                  {obj.map((data, i) => (
                    <span
                      key={i}
                      className="text_split_child_appendix d-inline-bloc"
                    >
                      {data}
                    </span>
                  ))}
                  &nbsp;
                </span>
              ))}
            </h1>
            <div className="hr-line text_split_child_appendix-3"></div>
            <p className="text-black mb-0">
              {newArr2.map((obj, i) => (
                <span
                  key={i}
                  className="add_clr heroText_width ff-raleway fs-preloader-rise"
                >
                  {obj.map((data, i) => (
                    <span key={i} className="text_split_child_appendix-2">
                      {data}
                    </span>
                  ))}
                  &nbsp;
                </span>
              ))}
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PreLoader;
