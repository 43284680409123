import React from "react";
import JmdNavbar from "./JmdNavbar";
import JmdHero from "./JmdHero";

const JmdHeader = () => {
  return (
    <section className="vh_100 header-bg header-sec d-flex flex-column">
      <JmdNavbar
        home="home"
        logonone="d-none"
        logoblock="d-block"
        navbg="nav-bg"
      />
      <JmdHero />
    </section>
  );
};

export default JmdHeader;
