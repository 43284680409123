import React from "react";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const IndustrialEleAdv = () => {
  return (
    <>
      <img loading="lazy" className="w-100 top-wave" src={topwave} alt="wave" />
      <section className="py-5 bg-grey product-advantage-sec">
        <div className="custom_container container">
          <h2 className="product-page-common-heading text-center">
            Advantages
          </h2>
          <div className="mt-5">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Heavy Load Capacity
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Industrial elevators excel in transporting heavy loads,
              streamlining the movement of materials within your facility. This
              efficiency enhances productivity and reduces manual labor,
              contributing to smoother operations
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Robust Durability
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Engineered for rugged environments, industrial elevators withstand
              harsh conditions, minimizing downtime and maintenance costs. Their
              durability ensures consistent performance and longevity, making
              them a reliable asset for industrial settings
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Customized Configurations
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Industrial elevators offer flexible customization to meet specific
              needs. From cargo dimensions to specialized controls, these
              elevators adapt to your unique workflow, optimizing material
              handling and transportation
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Enhanced Safety Features
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Safety is paramount. Industrial elevators are equipped with
              advanced safety mechanisms, ensuring secure transportation of
              materials and personnel. These features reduce risks and adhere to
              strict safety regulations
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Space Optimization
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Industrial elevators save valuable floor space by providing
              vertical transportation for goods and personnel. This
              space-efficient solution maximizes your facility's layout,
              increasing storage capacity and operational efficiency
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Cost-Efficient Workflow
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              By expediting material transportation, industrial elevators
              contribute to cost-effective workflows. They minimize delays,
              prevent inventory bottlenecks, and ultimately enhance overall
              efficiency, leading to improved business profitability.
            </p>
          </div>
        </div>
      </section>
      <img loading="lazy" className="w-100 bottom-wave" src={bottomwave} alt="wave" />
    </>
  );
};

export default IndustrialEleAdv;
