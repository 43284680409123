import React from "react";
import { Col, Row } from "react-bootstrap";
import capsuleElevator from "../assets/png/capusleElevator.png";
import {
  delivery,
  indiaMap,
  industryExperience,
  staff,
} from "../pages/MapData";
import map from "../assets/svg/indiaMap.svg";
import experiencestaff from "../assets/svg/staffExperience.svg";
import timedelivery from "../assets/svg/deliveryTime.svg";
import experience from "../assets/svg/experience.svg";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const WhyChooseUs = () => {
  return (
    <>
      <div className="pt-5">
        <img
          loading="lazy"
          className="w-100 top-wave"
          src={topwave}
          alt="wave"
        />
        <section className="py-5 bg-grey why-choose-us-sec">
          <div className="custom_container container py-lg-5 py-3">
            <Row className="align-items-center flex-column-reverse flex-lg-row">
              <Col xl={7} lg={6}>
                <div className="me-xl-5 pe-xl-5 pe-lg-4 text-center text-lg-start mt-4">
                  <h2 className="mb-0 common-heading-class">Why Choose Us?</h2>
                  <p className="mb-0 ff-arial fs-xsm lh-150 opacity-07 pe-xl-5 me-xl-5 mt-3">
                    Rise above the ordinary by selecting us as your elevator
                    solution partner. With over 13 years of industry experience,
                    we bring excellence to each project. Our dedicated team
                    ensures your vision becomes reality – from on-time delivery
                    to expert support. In North India, we're the trusted choice
                    that delivers quality and commitment.
                  </p>
                </div>
              </Col>
              <Col xl={5} lg={6}>
                <img
                  loading="lazy"
                  className="w-100"
                  src={capsuleElevator}
                  alt="capusle elevators"
                />
              </Col>
            </Row>
            <div className="mt-5 pt-xl-4 d-flex flex-column choose-us-data">
              <div className="d-flex align-items-lg-center justify-content-between flex-column flex-lg-row">
                <div className="d-flex align-items-center gap-3">
                  <div className="bg-blue-circle d-none d-sm-flex align-items-center justify-content-center">
                    {indiaMap}
                  </div>
                  <img
                    loading="lazy"
                    className="d-sm-none"
                    width={70}
                    src={map}
                    alt=""
                  />
                  <div>
                    <h3 className="mb-0 ff-merri lh-130 fs_xl">
                      All over North India
                    </h3>
                    <p className="mb-0 ff-arial fs-xsm lh-150 opacity-07 mt-2">
                      Serving all across North India with our
                      <br className="d-xl-none d-sm-block d-none" /> elevator
                      <br className="d-none d-xl-block" /> <span> </span>
                      expertise.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center pe-xl-4 me-xl-3 mt-4 mt-lg-0">
                  <div className="d-flex align-items-center gap-3">
                    <div className="bg-blue-circle d-none d-sm-flex align-items-center justify-content-center">
                      {staff}
                    </div>
                    <img
                      loading="lazy"
                      className="d-sm-none"
                      width={70}
                      src={experiencestaff}
                      alt=""
                    />
                    <div>
                      <h3 className="mb-0 ff-merri lh-130 fs_xl">
                        Experienced Staff
                      </h3>
                      <p className="mb-0 ff-arial fs-xsm lh-150 opacity-07 mt-2">
                        Our skilled team brings years of experience
                        <br className="d-xl-none d-sm-block d-none" /> to
                        <br className="d-none d-xl-block" /> every project.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-lg-center justify-content-between mt-lg-5 mt-4 flex-column flex-lg-row">
                <div className="d-flex align-items-center gap-3">
                  <div className="bg-blue-circle d-none d-sm-flex align-items-center justify-content-center">
                    {delivery}
                  </div>
                  <img
                    loading="lazy"
                    className="d-sm-none"
                    width={70}
                    src={timedelivery}
                    alt=""
                  />
                  <div>
                    <h3 className="mb-0 ff-merri lh-130 fs_xl">
                      Delivery In Time
                    </h3>
                    <p className="mb-0 ff-arial fs-xsm lh-150 opacity-07 mt-2">
                      Timely delivery is our commitment to your
                      <br className="d-none d-sm-block" /> project's success.
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center mt-4 mt-lg-0">
                  <div className="d-flex align-items-center gap-3">
                    <div className="bg-blue-circle d-sm-flex align-items-center justify-content-center d-none">
                      {industryExperience}
                    </div>
                    <img
                      loading="lazy"
                      className="d-sm-none"
                      width={70}
                      src={experience}
                      alt=""
                    />
                    <div>
                      <h3 className="mb-0 ff-merri lh-130 fs_xl">
                        <span className="ff-prata">13</span>
                        <span className="ff-poppins">+</span>
                        <span> </span>
                        Experience In Industry
                      </h3>
                      <p className="mb-0 ff-arial fs-xsm lh-150 opacity-07 mt-2">
                        Over 13 years of industry experience,
                        <br className="d-xl-none d-sm-block d-none" /> setting
                        us
                        <br className="d-none d-xl-block" /> apart.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <img
          loading="lazy"
          className="w-100 bottom-wave"
          src={bottomwave}
          alt="wave"
        />
      </div>
    </>
  );
};

export default WhyChooseUs;
