import React from "react";
import ProductHeader from "../components/ProductHeader";
import DumbWaiter from "../components/DumbWaiter";
import DumbEleAdv from "../components/DumbEleAdv";
import OurCollection from "../components/OurCollection";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";

const DumbWaiterElevator = () => {
  return (
    <>
      <ProductHeader />
      <DumbWaiter />
      <DumbEleAdv />
      <OurCollection />
      <GetInTouch />
      <JmdFooter />
    </>
  );
};

export default DumbWaiterElevator;
