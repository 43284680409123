import React from "react";
import { Row } from "react-bootstrap";
import { expertisecard } from "../pages/MapData";

const PageServiceSec = () => {
  return (
    <section className="py-5 elevator-expertise-sec">
      <div className="custom_container container">
        <h2 className="common-heading-class text-center">
          Discover Our Elevator Expertise
        </h2>
        <Row className="mt-5 justify-content-center">
          {expertisecard.map((val) => {
            return (
              <div className="col-lg-4 col-sm-6 mt-4">
                <div className="expertise-cards mx-auto expertise-cards-2 text-center px-4 py-4 position-relative h-100">
                  <div className="bg-blue-circle d-flex align-items-center justify-content-center mx-auto">
                    {val.cardicons}
                  </div>
                  <h3 className="mb-0  ff-merri fs_xxl lh-100 mt-4 pt-2">
                    {val.heading}
                  </h3>
                  <p className="mt-3 ff-arial lh-150 fs_xsm opacity-07">
                    {val.para}
                  </p>
                </div>
              </div>
            );
          })}
        </Row>
      </div>
    </section>
  );
};

export default PageServiceSec;
