import React from "react";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const GoodsEleAdv = () => {
  return (
    <>
      <img loading="lazy" className="w-100 top-wave" src={topwave} alt="wave" />
      <section className="py-5 bg-grey product-advantage-sec">
        <div className="custom_container container">
          <h2 className="product-page-common-heading text-center">
            Advantages
          </h2>
          <div className="mt-5">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Efficient Transportation
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Goods elevators streamline the movement of heavy loads within your
              facility, enhancing operational efficiency by minimizing manual
              labor and expediting logistics.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Robust Durability
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Engineered for durability, goods elevators withstand rigorous use,
              ensuring reliable performance over time. This durability
              translates to reduced maintenance needs and increased
              productivity.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Flexible Configurations
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Goods elevators offer customizable configurations to accommodate
              varying load sizes and types. Their adaptability optimizes
              material handling, allowing seamless transportation of goods
              across different industries.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Safety Assurance
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Prioritizing safety, goods elevators feature advanced safety
              features such as door interlocks, overload sensors, and emergency
              controls. These measures ensure secure transportation of goods and
              personnel.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Space Maximization
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Goods elevators effectively utilize vertical space, providing
              efficient storage and transportation solutions for warehouses and
              businesses with limited floor space.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Time and Cost Savings
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              By streamlining material movement, goods elevators contribute to
              reduced operational time and costs. Their swift transportation
              minimizes workflow disruptions, boosting overall productivity and
              business profitability.
            </p>
          </div>
        </div>
      </section>
      <img loading="lazy" className="w-100 bottom-wave" src={bottomwave} alt="wave" />
    </>
  );
};

export default GoodsEleAdv;
