import React from "react";
import { Row } from "react-bootstrap";
import { collectionsec } from "../pages/MapData";
import Slider from "react-slick";
import {
  expertisecardnextarrow,
  expertisecardprevarrow,
} from "../pages/MapData";
import { Link } from "react-router-dom";

const OurCollection = () => {
  const button = React.useRef();
  var settings = {
    autoplay: true,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          infinite: true,
        },
      },
    ],
  };
  return (
    <section className="py-5 sec-our-collection">
      <div className="custom_container container py-xl-5 py-lg-4">
        <h2 className="common-heading-class text-center">
          Explore Our Collection
        </h2>
        <Row className="mt-5 d-none d-md-flex">
          {collectionsec.map((val) => {
            return (
              <div className="col-4 mt-4">
                <div className="collection-img-div overflow-hidden">
                  <img loading="lazy"
                    className="w-100 transition-300"
                    src={val.img}
                    alt="elevator design"
                  />
                </div>
              </div>
            );
          })}
        </Row>
        <Slider className="d-md-none" {...settings} ref={button}>
          {collectionsec.map((val) => {
            return (
              <div className="col-4 mt-3 px-2">
                <div className="collection-img-div overflow-hidden">
                  <img loading="lazy"
                    className="w-100 transition-300"
                    src={val.img}
                    alt="elevator design"
                  />
                </div>
              </div>
            );
          })}
        </Slider>
        <div className="d-flex d-md-none justify-content-center gap-3 mt-5">
          <button
            className="prev-next-btn d-flex align-items-center justify-content-center transition-300"
            onClick={() => button.current.slickPrev()}
          >
            {expertisecardnextarrow}
          </button>
          <button
            className="prev-next-btn d-flex align-items-center justify-content-center transition-300"
            onClick={() => button.current.slickNext()}
          >
            {expertisecardprevarrow}
          </button>
        </div>
        <div className="text-center mt-5">
          <Link to="/gallery">
            <button className="common-button ff-petrona fs_md">
              Discover More
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default OurCollection;
