import React from "react";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const DumbEleAdv = () => {
  return (
    <>
      <img loading="lazy" className="w-100 top-wave" src={topwave} alt="wave" />
      <section className="py-5 bg-grey product-advantage-sec">
        <div className="custom_container container">
          <h2 className="product-page-common-heading text-center">
            Advantages
          </h2>
          <div className="mt-5">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Efficient Transport
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Dumbwaiter elevators provide swift, automated item transport,
              reducing the need for manual handling and expediting service
              delivery.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">Space-Saving</h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Their compact design optimizes floor space utilization, a valuable
              asset in restaurants, hotels, and homes.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Enhanced Service
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Dumbwaiters streamline logistics, improving efficiency and service
              quality in hospitality and residential settings.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              User-Friendly
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              User-friendly controls ensure hassle-free operation, making these
              elevators accessible to a wide range of users.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">Versatile</h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              These elevators cater to diverse industries, adapting effortlessly
              to various logistical requirements, from food service to
              healthcare and beyond.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">Dependable</h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Known for their reliable performance, dumbwaiter elevators
              guarantee that items are transported safely and consistently.
            </p>
          </div>
        </div>
      </section>
      <img loading="lazy" className="w-100 bottom-wave" src={bottomwave} alt="wave" />
    </>
  );
};

export default DumbEleAdv;
