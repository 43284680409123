import React from "react";
import { Col, Row } from "react-bootstrap";
import industrialElevator from "../assets/png/prodindusele.png";

const IndustrialEle = () => {
  return (
    <section className="py-5">
      <div className="custom_container container pb-lg-5">
        <h1 className="ff-baskerville fs_3xl lh-100 product-heading-text-stroke text-center">
          Industrial Elevators
        </h1>
        <Row className="mt-5 pt-lg-5 pt-3 align-items-center flex-column-reverse flex-lg-row">
          <Col lg={6}>
            <div className="text-center text-lg-start mt-4">
              <h2 className="product-page-common-heading">
                Elevating Your Business
              </h2>
              <p className="ff-arial clr-light-black-2 lh-150 fs_xsm pe-lg-5 mb-0 mt-3">
                Experience efficient vertical transportation in industrial
                settings with JMD Elevators. Our Industrial Elevators are built
                for durability and reliability, ensuring smooth movement of
                materials and personnel. Designed to withstand demanding
                environments, these elevators optimize workflow and
                productivity. Trust us to provide safe and robust vertical
                mobility solutions that fit industrial requirements.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="image-div-border-radius overflow-hidden">
              <img loading="lazy"
                className="w-100 image-border-radius transition-300 img-hover"
                src={industrialElevator}
                alt="elevator"
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default IndustrialEle;
