import React from "react";
import ChairEle from "../components/ChairEle";
import ProductHeader from "../components/ProductHeader";
import ChaieEleAdv from "../components/ChaieEleAdv";
import OurCollection from "../components/OurCollection";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";

const ChairElevator = () => {
  return (
    <>
      <ProductHeader />
      <ChairEle />
      <ChaieEleAdv />
      <OurCollection />
      <GetInTouch />
      <JmdFooter />
    </>
  );
};

export default ChairElevator;
