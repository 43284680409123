import React from "react";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";

const CarEleAdv = () => {
  return (
    <>
      <img className="w-100 top-wave" src={topwave} alt="wave" />
      <section className="py-5 bg-grey product-advantage-sec">
        <div className="custom_container container">
          <h2 className="product-page-common-heading text-center">
            Advantages
          </h2>
          <div className="mt-5">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Space Optimization
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Car elevators maximize parking space by vertically stacking
              vehicles, making them ideal for compact urban environments where
              land is precious.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Time Efficiency
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              These elevators offer swift vehicle access, reducing the time
              spent searching for parking spots. Drivers can conveniently park
              or retrieve their cars with minimal hassle.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Enhanced Security
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Car elevators provide heightened vehicle security by restricting
              unauthorized access and potential theft, ensuring a safe and
              protected parking environment for owners.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Modern Design
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Designed to seamlessly blend with building aesthetics, car
              elevators enhance exterior and interior design, adding a touch of
              modernity and luxury.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">Eco-Friendly</h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Car elevators contribute to sustainable urban practices by
              reducing the need for vast parking lots. This conservation of land
              not only promotes environmental responsibility but also helps
              alleviate urban congestion, contributing to a greener and more
              livable cityscape.
            </p>
          </div>
          <div className="mt-4 pt-lg-3">
            <h4 className=" ff-merri fs_lg lh-100 ls-048 mb-0">
              Status Symbol
            </h4>
            <p className="mb-0 ff-arial fs_xsm lh-150 clr-light-black mt-2 pt-1">
              Owning a car elevator is a symbol of luxury and exclusivity.
              Beyond its functional benefits, it reflects a sophisticated
              lifestyle and showcases the owner's discerning taste. This
              addition enhances property value and makes a bold statement about
              the level of refinement associated with the owner and their
              property.
            </p>
          </div>
        </div>
      </section>
      <img loading="lazy" className="w-100 bottom-wave" src={bottomwave} alt="wave" />
    </>
  );
};

export default CarEleAdv;
