import React from "react";
import ProductHeader from "../components/ProductHeader";
import CarEle from "../components/CarEle";
import CarEleAdv from "../components/CarEleAdv";
import OurCollection from "../components/OurCollection";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";

const CarElevator = () => {
  return (
    <>
      <ProductHeader />
      <CarEle />
      <CarEleAdv />
      <OurCollection />
      <GetInTouch />
      <JmdFooter />
    </>
  );
};

export default CarElevator;
