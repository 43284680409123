import React from "react";
import ProductHeader from "../components/ProductHeader";
import GoodsEle from "../components/GoodsEle";
import GoodsEleAdv from "../components/GoodsEleAdv";
import OurCollection from "../components/OurCollection";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";

const GoodsElevators = () => {
  return (
    <>
      <ProductHeader />
      <GoodsEle />
      <GoodsEleAdv />
      <OurCollection />
      <GetInTouch />
      <JmdFooter />
    </>
  );
};

export default GoodsElevators;
