import React from "react";
import { Col, Row } from "react-bootstrap";
import deliverytruck from "../assets/png/delivery.png";

const DeliveryInTime = () => {
  return (
    <section className="py-5">
      <div className="custom_conatiner container">
        <Row className="align-items-center justify-content-between">
          <Col className="pe-lg-5 pe-xl-0 text-center" xl={5} lg={6}>
            <img loading="lazy" className="deliverytruck " src={deliverytruck} alt="Truck" />
          </Col>
          <Col className="text-center text-lg-start mt-4" xl={6} lg={6}>
            <h2 className="product-page-common-heading">Delivery In Time</h2>
            <p className="mb-0 ff-arial opacity-07 lh-150 fs_xsm">
              We prioritize timely delivery in all our projects. Our commitment
              to punctuality ensures your elevator installations and services
              are completed on schedule, minimizing disruptions and maximizing
              your building's operational efficiency. Count on us for reliable
              and on-time project delivery
            </p>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default DeliveryInTime;
