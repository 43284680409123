import React from "react";
import { Col, Row } from "react-bootstrap";
import northIndiamap from "../assets/png/northindiamap.png";

const AllNorthIndia = () => {
  return (
    <section className="py-5">
      <div className="custom_conatiner container">
        <Row className="align-items-center justify-content-between">
          <Col className="pe-lg-5 pe-xl-0" xl={5} lg={6}>
            <div className="py-3 map-border text-center northindiamap mx-auto">
              <img loading="lazy" src={northIndiamap} alt="North India Mao" />
            </div>
          </Col>
          <Col className="text-center text-lg-start mt-4" xl={6} lg={6}>
            <h2 className="product-page-common-heading">
              All over North India
            </h2>
            <p className="mb-0 ff-arial opacity-07 lh-150 fs_xsm">
              Delivering comprehensive elevator solutions across North India,
              our company stands out with unmatched expertise, an unwavering
              safety commitment, and a steadfast dedication to client
              satisfaction. We're your trusted partner for elevating vertical
              mobility, offering excellence, reliability, and innovation that
              exceeds industry standards. Elevate your expectations with us.
            </p>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default AllNorthIndia;
