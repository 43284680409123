import React from "react";
import AboutUsPageHeader from "../components/AboutUsPageHeader";
import JmdHistory from "../components/JmdHistory";
import CompanyGoal from "../components/CompanyGoal";
import MissionAndVision from "../components/MissionAndVision";
import topwave from "../assets/png/ourServicetopwave.png";
import bottomwave from "../assets/png/ourServicebottomwave.png";
import AllNorthIndia from "../components/AllNorthIndia";
import ExperiencedStaf from "../components/ExperiencedStaf";
import DeliveryInTime from "../components/DeliveryInTime";
import IndustryExperience from "../components/IndustryExperience";
import OurCollection from "../components/OurCollection";
import OurClients from "../components/OurClients";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";

const AboutUs = () => {
  return (
    <>
      <AboutUsPageHeader />
      <JmdHistory />
      <CompanyGoal />
      <MissionAndVision />
      <div className="my-5">
        <img className="w-100 top-wave" src={topwave} alt="wave" />
        <div className="bg-grey product-advantage-sec py-5">
          <h1 className="common-heading-class text-center">Why Choose Us?</h1>
          <AllNorthIndia />
          <ExperiencedStaf />
          <DeliveryInTime />
          <IndustryExperience />
        </div>
        <img className="w-100 bottom-wave" src={bottomwave} alt="wave" />
      </div>
      <OurCollection />
      <OurClients />
      <GetInTouch aboutusbg="bg-transparent" wave="d-none" />
      <JmdFooter />
    </>
  );
};

export default AboutUs;
