import React from "react";
import ProductHeader from "../components/ProductHeader";
import GlassEle from "../components/GlassEle";
import GlassEleAdv from "../components/GlassEleAdv";
import OurCollection from "../components/OurCollection";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";

const GlassElevator = () => {
  return (
    <>
      <ProductHeader />
      <GlassEle />
      <GlassEleAdv />
      <OurCollection />
      <GetInTouch />
      <JmdFooter />
    </>
  );
};

export default GlassElevator;
