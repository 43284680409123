import React from "react";
import ProductHeader from "../components/ProductHeader";
import EleLopCop from "../components/EleLopCop";
import EleLopCopAdv from "../components/EleLopCopAdv";
import OurCollection from "../components/OurCollection";
import GetInTouch from "../components/GetInTouch";
import JmdFooter from "../components/JmdFooter";

const LopCop = () => {
  return (
    <>
      <ProductHeader />
      <EleLopCop />
      <EleLopCopAdv />
      <OurCollection />
      <GetInTouch />
      <JmdFooter />
    </>
  );
};

export default LopCop;
