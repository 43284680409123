import React from "react";
import { Col, Row } from "react-bootstrap";
import carElevator from "../assets/png/prodcarele.png";

const CarEle = () => {
  return (
    <section className="py-5">
      <div className="custom-conatiner container">
        <h1 className="ff-baskerville fs_3xl lh-100 product-heading-text-stroke text-center">
          Car Elevators
        </h1>
        <Row className="mt-5 pt-lg-5 pt-3 align-items-center flex-column-reverse flex-lg-row">
          <Col lg={6}>
            <div className="text-center text-lg-start mt-4">
              <h2 className="product-page-common-heading">Car Elevator Ease</h2>
              <p className="ff-arial clr-light-black-2 lh-150 fs_xsm pe-lg-5 mb-0 mt-3">
                Experience innovation in parking solutions with JMD Elevators'
                Car Elevators. Designed to optimize space, our elevators provide
                efficient and secure vehicle transport within buildings. Whether
                for homes or businesses, these elevators offer a practical and
                stylish solution for parking challenges. Trust us to deliver
                reliable and safe car elevator solutions, transforming the way
                you park and access vehicles.
              </p>
            </div>
          </Col>
          <Col lg={6}>
            <div className="image-div-border-radius overflow-hidden">
              <img loading="lazy"
                className="w-100 image-border-radius transition-300 img-hover"
                src={carElevator}
                alt="elevator"
              />
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default CarEle;
